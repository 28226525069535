import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type Store = {
    manuallyLogout: any
    setManuallyLogout: (value: any) => void
}

export const useManuallyLogout = create((set, get) => ({
    manuallyLogout: null,
    setManuallyLogout: (value: boolean) => set({ manuallyLogout: value }),
}))

export const useStore = create<Store>()(
    devtools((set, get) => ({
        manuallyLogout: null,
        setManuallyLogout: (value: boolean) => set({ manuallyLogout: value }),
    }))
)
