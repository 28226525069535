import {AxiosInstance} from 'axios'

export const setResponseInterceptors = (axiosInstance: AxiosInstance): void => {
    axiosInstance.interceptors.response.use(
        async (response: any) => {
            return Promise.resolve(response)
        },
        async (error: any) => {
            if (
                error.response?.status === 401 ||
                error.response?.status === 403 ||
                error.message === "Network Error"
            ) {
                if (localStorage.getItem('userRole')) {
                    localStorage.removeItem('userRole')
                }
                if (error.config.url !== '/login') {
                    // @ts-ignore
                    localStorage.setItem('manuallyLogout', false)
                }
            }
            
            return Promise.reject(error)
        }
    )
}
