import {setResponseInterceptors} from './axiosInterceptors'
import axios, {AxiosInstance, AxiosPromise} from 'axios'
import {UserLogin} from '../typings/user.d'
import {VifUploadResult} from '../typings/summary.d'

class UserApi {
    constructor(private axiosInstance: AxiosInstance) {
    }
    
    login(benutzer: UserLogin): AxiosPromise<void> {
        const json = JSON.stringify(benutzer)
        let config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        
        return this.axiosInstance.post('/login', json, config)
    }
    
    logout(): AxiosPromise<void> {
        return this.axiosInstance.post<void>('/logout')
    }
}

class MappingApi {
    constructor(private axiosInstance: AxiosInstance) {
    }
    
    postCredentialsJson(file: File): AxiosPromise<any> {
        const config = {headers: {'Content-Type': 'multipart/form-data'}}
        const formData = new FormData()
        // @ts-ignore
        formData.append('file', file[0])
        
        return this.axiosInstance.post<any>('/credentials/', formData, config)
    }
    
    getCredentialsJson(): AxiosPromise<any> {
        return this.axiosInstance.get<any>('/credentials/')
    }
    
    postIcsmsMappingJson(file: File): AxiosPromise<any> {
        const config = {headers: {'Content-Type': 'multipart/form-data'}}
        const formData = new FormData()
        // @ts-ignore
        formData.append('file', file[0])
        
        return this.axiosInstance.post<any>('/icsmsmapping/', formData, config)
    }
    
    getIcsmsMappingJson(): AxiosPromise<any> {
        return this.axiosInstance.get<any>('/icsmsmapping/')
    }
    
    postMapping(file: File): AxiosPromise<any> {
        const config = {headers: {'Content-Type': 'multipart/form-data'}}
        const formData = new FormData()
        // @ts-ignore
        formData.append('file', file[0])
        
        return this.axiosInstance.post<any>('/mapping/', formData, config)
    }
    
    getMapping(): AxiosPromise<any> {
        return this.axiosInstance.get<any>('/mapping/')
    }
}

class VifApi {
    constructor(private axiosInstance: AxiosInstance) {
    }
    
    postVifFile(file: File): AxiosPromise<VifUploadResult> {
        const config = {headers: {'Content-Type': 'multipart/form-data'}}
        const formData = new FormData()
        // @ts-ignore
        formData.append('file', file[0])
        
        return this.axiosInstance.post<VifUploadResult>('/vis/', formData, config)
    }
    
    getReferenceDataFromDrpiType(drpiType: string): AxiosPromise<any> {
        return this.axiosInstance.get<any>(
            `/icsms/referencedata?drpitype=${drpiType}`
        )
    }
    
    getResultProtocol(uuid: string): AxiosPromise<Blob> {
        return this.axiosInstance.get<Blob>(
            `/icsms/protocol/${uuid}`, {responseType: 'blob'}
        )
    }
    
    postRequestsToIcsms(uuid: string): AxiosPromise<any> {
        return this.axiosInstance.post<any>(`/icsms/${uuid}`
        )
    }
    
    getProductInformation(piid: string): AxiosPromise<any> {
        return this.axiosInstance.get<any>(
            `/icsms/product-information?piid=${piid}`
        )
    }
    
    getDRPIInformation(cid: string): AxiosPromise<any> {
        return this.axiosInstance.get<any>(
            `/icsms/drpi-information?cid=${cid}`
        )
    }
}

class UserRoleApi {
    constructor(private axiosInstance: AxiosInstance) {
    }
    
    getCurrentUserRole(): AxiosPromise<string> {
        return this.axiosInstance.get<string>('/role/')
    }
}

export class Api {
    private axiosInstance = axios.create({
        baseURL: '/api',
        timeout: 120000,
        withCredentials: true,
    })
    
    constructor() {
        setResponseInterceptors(this.axiosInstance)
    }
    
    public mapping = new MappingApi(this.axiosInstance)
    public user = new UserApi(this.axiosInstance)
    public vif = new VifApi(this.axiosInstance)
    public userRole = new UserRoleApi(this.axiosInstance)
}

export const $api = new Api()
