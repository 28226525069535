import React from 'react'
import {Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {NavLink} from 'react-router-dom'
import {$api} from '../api/callApi'
// @ts-ignore
import {ReactComponent as MainLogo} from '../assets/sh-logo_vis-to-icsms.svg'

const useStyles = makeStyles((theme: any) => ({
    header: {
        borderBottom: '1px solid ' + theme.colors.grey,
        margin: '0!important',
        padding: '0!important',
    },
    production: {
        background: theme.colors.red,
        color: 'white',
        width: 'fit-content',
        padding: '0.5em 2em 0.7em',
        margin: '0 auto!important',
        marginBottom: '0.5em!important',
    },
    mainLogo: {
        padding: '0.25em 0 0.25em 0',
        margin: 'auto',
        display: 'block',
    },
    menuItem: {
        textDecoration: 'none',
        '&.active': {
            '& p': {
                padding: 'calc(1.25em - 4px) 0.5em 1.25em',
                background: theme.colors.redDark,
                borderTop: '4px solid ' + theme.colors.red,
                color: theme.colors.white,
            },
        },
        '& p': {
            color: theme.colors.blue,
            padding: '1.25em 0.5em',
            textAlign: 'center',
            '&:hover': {
                background: theme.colors.redDark,
                cursor: 'pointer',
                color: theme.colors.white,
            },
        },
    },
    menuItemLogout: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textDecoration: 'none',
        '& p': {
            color: theme.colors.blue,
            padding: '1.25em 0.5em',
            textAlign: 'center',
            '&:hover': {
                background: theme.colors.redDark,
                cursor: 'pointer',
                color: theme.colors.white,
            },
        },
    },
    spacingForMoreMenuItems: {
        marginRight: '-5em',
        marginLeft: '4em',
    },
    
    textOverFlowEllipsis: {
        overflow: "hidden",
        whiteSpace: 'nowrap',
        textOverflow: "ellipsis"
    },
}))

export const Header = () => {
    const classes = useStyles()
    const logedInUser = localStorage.getItem('userRole')
    const handleLogout = () => {
        $api.user.logout().then((resp) => {
            setTimeout(() => {
                localStorage.removeItem('userRole')
                localStorage.setItem('manuallyLogout', true)
                setTimeout(() => {
                    window.location.href = '/login'
                }, 1000)
                $api.userRole
                    .getCurrentUserRole()
                    .then((resp) => {
                    
                    })
                    .catch((error) => {
                        localStorage.removeItem('userRole')
                        localStorage.setItem('manuallyLogout', true)
                        setTimeout(() => {
                            window.location.href = '/login'
                        }, 1000)
                    })
            })
        })
    }
    
    const gridNormalUser = () => (
        <>
            <Grid
                style={{visibility: logedInUser ? 'visible' : 'hidden'}}
                container
                py={0}
                alignItems="center"
                xs={3}
            >
                <Grid item py={0} xs={6}>
                    <NavLink to="/" className={classes.menuItem}>
                        <Typography>Start</Typography>
                    </NavLink>
                </Grid>
                <Grid item py={0} xs={6}>
                    <NavLink
                        to=""
                        onClick={() => handleLogout()}
                        className={classes.menuItemLogout}
                    >
                        <Typography>Abmelden</Typography>
                    </NavLink>
                </Grid>
            </Grid>
        </>
    )
    const gridAdminUser = () => (
        <>
            <Grid container py={0} alignItems="center" xs={4}>
                <Grid item py={0} xs={1.5}>
                    <NavLink to="/" className={classes.menuItem}>
                        <Typography className={classes.textOverFlowEllipsis}>Start</Typography>
                    </NavLink>
                </Grid>
                <Grid item py={0} xs={2.8}>
                    <NavLink to="/settings" className={classes.menuItem}>
                        <Typography className={classes.textOverFlowEllipsis}>Einstellungen</Typography>
                    </NavLink>
                </Grid>
                <Grid item py={0} xs={3}>
                    <NavLink to="/referencedata" className={classes.menuItem}>
                        <Typography className={classes.textOverFlowEllipsis}>Referenzdaten</Typography>
                    </NavLink>
                </Grid>
                <Grid item py={0} xs={2.7}>
                    <NavLink to="/pidrpidata" className={classes.menuItem}>
                        <Typography className={classes.textOverFlowEllipsis}>PI-/DRPI-Daten
                        </Typography>
                    </NavLink>
                </Grid>
                <Grid item py={0} xs={2}>
                    <NavLink
                        to=""
                        onClick={() => handleLogout()}
                        className={classes.menuItemLogout}
                    >
                        <Typography className={classes.textOverFlowEllipsis}>Abmelden</Typography>
                    </NavLink>
                </Grid>
            </Grid>
        </>
    )
    
    function getStage() {
        let stage = document?.querySelector('meta[name="stage"]')?.content
        if (stage.match(/test/i)) {
            return "Testsystem"
        } else if (stage.match(/prod/i)) {
            return "Produktivsystem"
        }
        return "Dev-System"
    }
    
    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="center"
                my={1}
                spacing={1}
                className={classes.header}
            >
                <Grid item py={0} xs={3}>
                    <MainLogo className={classes.mainLogo}/>
                </Grid>
                <Grid
                    item
                    py={0}
                    xs={logedInUser === 'ROLE_USER_ADMIN' ? 5 : 6}
                    style={{
                        marginLeft:
                            logedInUser === 'ROLE_USER_ADMIN' ? '5em' : '0em',
                        marginRight:
                            logedInUser === 'ROLE_USER_ADMIN' ? '-5em' : '0em',
                    }}
                >
                    <Typography className={classes.production}>
                        {getStage()}
                    </Typography>
                </Grid>
                
                {logedInUser != null && logedInUser === 'ROLE_USER_ADMIN'
                    ? gridAdminUser()
                    : gridNormalUser()}
            </Grid>
        </>
    )
}
