import * as React from 'react'
import {useEffect} from 'react'

import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import {$api} from '../api/callApi'
import {UserLogin} from '../typings/user.d'
import useNotification from '../components/useNotifcation'
import {useTranslation} from 'react-i18next'
import {Header} from '../components/Header'
import {makeStyles, useTheme} from '@mui/styles'
import {Theme} from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {ReactComponent as ArrowRight} from '../assets/icon.arrow-right.svg'

const useStyles = makeStyles((theme: Theme) => ({
    rootDropzone: {
        borderTop: '4px solid ' + theme.colors.red,
        background: theme.colors.greyLight,
        marginTop: '2em',
    },
}))

export const Login = () => {
    const [msg, sendNotification] = useNotification()
    const {t} = useTranslation() as any
    const classes = useStyles()
    const theme: Theme = useTheme()
    const [showPassword, setShowPassword] = React.useState(false)
    
    const handleClickShowPassword = () => setShowPassword((show) => !show)
    
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault()
    }
    
    function getUserRoleAndSetToLocalStorage() {
        $api.userRole
            .getCurrentUserRole()
            .then((resp) => {
                localStorage.setItem('userRole', resp.data)
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000)
            })
            .catch((error) => {
                localStorage.removeItem('userRole')
                //@ts-ignore
                localStorage.setItem('manuallyLogout', false)
                window.location.href = '/login'
            })
    }
    
    function removeUserFromLocalStorageAndRedirectToLogin() {
        localStorage.removeItem('userRole')
        //@ts-ignore
        localStorage.setItem('manuallyLogout', false)
        window.location.href = '/login'
    }
    
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        let userLogin: UserLogin = {
            username: data.get('email') as string,
            password: data.get('password') as string,
        }
        $api.userRole
            .getCurrentUserRole()
            .then((resp) => {
                //Das ist der Fall wenn die Session abgelaufen ist, returned spring ein "whiteable error html doc" was bedeutet dass die Session abgelaufen ist
                if (isHtml(resp.data)) {
                    removeUserFromLocalStorageAndRedirectToLogin()
                }
            })
            .catch((error) => {
                $api.user
                    .login(userLogin)
                    .then((resp) => {
                        if (resp) {
                            getUserRoleAndSetToLocalStorage()
                        }
                    })
                    .catch((error) => {
                        // @ts-ignore
                        sendNotification({
                            msg: 'Etwas ist schiefgelaufen. Bitte Benutzername oder Passwort überprüfen!',
                            variant: 'error',
                        })
                    })
            })
        
    }
    
    useEffect(() => {
        console.log(msg)
        if (localStorage.getItem('manuallyLogout') === 'false') {
            //@ts-ignore
            sendNotification({
                msg: 'Sitzung ist abgelaufen. Sie wurden automatisch abgemeldet.',
                variant: 'warning',
            })
        } else if (localStorage.getItem('manuallyLogout') === 'true') {
            //@ts-ignore
            sendNotification({
                msg: t('logout.successfullLogout'),
                variant: 'success',
            })
        }
        localStorage.removeItem('manuallyLogout')
    })
    const isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i)
    
    return (
        <>
            <CssBaseline/>
            <Header/>
            <Container sx={{ml: 'auto', mr: 'auto'}} component="main">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography sx={{mt: 9}} component="h1" variant="h4">
                        {t('login')}
                    </Typography>
                    <div className={classes.rootDropzone}>
                        <div style={{padding: '2em'}}>
                            <Box
                                component="form"
                                onSubmit={handleSubmit}
                                noValidate
                                sx={{mt: 1}}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Benutzername"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Passwort"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff/>
                                                    ) : (
                                                        <Visibility/>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                        mt: 3,
                                        mb: 3,
                                        textTransform: 'capitalize',
                                        float: 'right',
                                        width: 'auto',
                                        '&:hover': {
                                            backgroundColor:
                                                theme.colors.red + '!important',
                                            color: 'white',
                                        },
                                    }}
                                    endIcon={
                                        <ArrowRight
                                            style={{
                                                width: '0.8em',
                                                height: '0.8em',
                                            }}
                                        />
                                    }
                                >
                                    {'Anmelden'}
                                </Button>
                            </Box>
                        </div>
                    </div>
                </Box>
            </Container>
        </>
    )
}
