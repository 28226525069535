import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { App } from './App'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import './i18n.ts'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
document.body.style.overflow = 'hidden'
root.render(
    <BrowserRouter>
        <SnackbarProvider maxSnack={1}>
            <App />
        </SnackbarProvider>
    </BrowserRouter>
)
