import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import de from "./locales/de/de.json";

const resources = {
    de: {
        translation: de,
    },
};

i18n.use(initReactI18next as any).init({
    resources,
    lng: "de",

    interpolation: {
        escapeValue: false,
    },
} as any).then(r => ( console.log("init")) );

export default i18n;
