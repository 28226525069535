import * as React from 'react'
import {useState} from 'react'

import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import {Header} from '../components/Header'
import {makeStyles, useTheme} from '@mui/styles'
import {Backdrop, Box, Button, CircularProgress, Grid, Modal, Tab, Tabs, Typography,} from '@mui/material'
import {ReactComponent as ErrorLogo} from '../assets/icon.file-error.svg'
import {Theme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {$api} from "../api/callApi";

const useStyles = makeStyles((theme: Theme) => ({
    textArea: {
        maxHeight: '530px',
        width: '100em',
        maxWidth: '100em',
    },
    modal: {
        width: 'auto',
        padding: '2em 2em 2em',
        marginTop: '25vh',
        background: theme.colors.greyLight,
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
}))

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

export const PiDrpiDataPage = () => {
    const theme: Theme = useTheme()

    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const [productInformationInputId, setProductInformationInputId] = React.useState("")
    const [drpiInformationInputId, setDrpiInformationInputId] = React.useState("")
    const [productInformation, setProductinformation] = useState('')
    const [drpiInformations, setDrpiInformations] = useState('')
    const [openNoCredentialsFound, setOpenNoCredentialsFound] = useState(false)
    const [openErrorOnlyNumbersAllowed, setOpenErrorOnlyNumbersAllowed] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [openError, setOpenError] = useState(false)
    const [open, setOpen] = React.useState(false)

    const handleCloseBackdropClick = () => {
        setOpenNoCredentialsFound(false)
    }

    function CustomTabPanel(props: TabPanelProps) {
        const {children, value, index, ...other} = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{p: 3}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        )
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    function prettifyXml(xml: string) { // tab = optional indent value, default is tab (\t)
        var formatted = '', indent = '';
        var tab = '\t';
        xml.split(/>\s*</).forEach(function (node) {
            if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
            formatted += indent + '<' + node + '>\r\n';
            // eslint-disable-next-line
            if (node.match(/^<?\w[^>]*[^\/]$/)) indent += tab;              // increase indent
        });
        return formatted.substring(1, formatted.length - 3);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    function throwException(error: any) {
        if (!openError) {
            setOpenError(true)
            setErrorMessage(error.response.data.message)
            setOpen(false)
        }
    }

    function getDRPIInformation() {
        setOpen(true)
        $api.vif.getDRPIInformation(drpiInformationInputId).then((resp) => {
            if (resp) {
                setDrpiInformations(prettifyXml(resp.data))
                setOpen(false)
            }
        })
            .catch((error) => {
                throwException(error)
            })

    }

    function getProductInformation() {
        setOpen(true)
        const containsLetters = RegExp.prototype.test.bind(/[A-Za-z]+/i)
        if (containsLetters(productInformationInputId)) {
            setOpenErrorOnlyNumbersAllowed(true)
            setOpen(false)
        } else {
            $api.vif.getProductInformation(productInformationInputId).then((resp) => {
                if (resp) {
                    setProductinformation(prettifyXml(resp.data))
                    setOpen(false)
                }
            })
                .catch((error) => {
                    throwException(error)
                })
        }
    }

    return (
        <>
            <CssBaseline/>
            <Header/>
            <div>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </div>
            <Container sx={{ml: 'auto', mr: 'auto'}} component="main">
                <Grid container justifyContent="center" spacing={1} style={{marginTop: "0.1em"}}>
                    <h1 style={{fontWeight: 'lighter'}}>
                        Produkt-/DRPI-Informationen
                    </h1>
                </Grid>
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Produkt-Informationen" {...a11yProps(0)} />
                            <Tab label="DRPI-Informationen" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Grid
                            container
                            justifyContent="center"
                            mt={3}
                            spacing={1}
                        >
                            <Grid container
                                  justifyContent="left"
                                  spacing={1}
                                  py={0}
                                  style={{marginLeft: "unset"}}
                            >
                                <TextField
                                    className={classes.input}
                                    id="outlined-required"
                                    value={productInformationInputId}
                                    autoFocus={true}
                                    onChange={(v) => {
                                        setProductInformationInputId(v.target.value);
                                    }}
                                    label={"Eingabe der ID"}
                                    style={{marginBottom: '2em', width: "30%"}}
                                />
                                <Button sx={{
                                    backgroundColor: theme.colors.white,
                                    border:
                                        '1px solid ' + theme.colors.blue,
                                    '&:hover': {
                                        backgroundColor: theme.colors.blue,
                                        color: theme.colors.white,
                                        cursor: 'pointer',
                                    },
                                    height: "4.1em",
                                    marginLeft: "2em"
                                }} onClick={() => {
                                    getProductInformation()
                                }}>
                                    Absenden
                                </Button>

                            </Grid>
                            <textarea
                                readOnly={true}
                                className={classes.textArea}
                                rows={30}
                                defaultValue={productInformation}
                            />
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Grid
                            container
                            justifyContent="center"
                            mt={3}
                            spacing={1}
                        >
                            <Grid container
                                  justifyContent="left"
                                  spacing={1}
                                  py={0}
                                  style={{marginLeft: "unset"}}
                            >
                                <TextField
                                    id="outlined-required"
                                    value={drpiInformationInputId}
                                    autoFocus={true}
                                    onChange={(v) => setDrpiInformationInputId(v.target.value)}
                                    label={"Eingabe der ID"}
                                    style={{marginBottom: '2em', width: "30%"}}
                                />
                                <Button sx={{
                                    backgroundColor: theme.colors.white,
                                    border:
                                        '1px solid ' + theme.colors.blue,
                                    '&:hover': {
                                        backgroundColor: theme.colors.blue,
                                        color: theme.colors.white,
                                        cursor: 'pointer',
                                    },
                                    height: "4.1em",
                                    marginLeft: "2em"
                                }} onClick={() => {
                                    getDRPIInformation()
                                }}>
                                    Absenden
                                </Button>
                            </Grid>
                            <textarea
                                readOnly={true}
                                className={classes.textArea}
                                rows={30}
                                defaultValue={drpiInformations}
                            />
                        </Grid>
                    </CustomTabPanel>
                </Box>
            </Container>
            <Modal
                open={openNoCredentialsFound}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <ErrorLogo
                                        style={{width: '5em', height: '5em'}}
                                    />
                                    <Typography
                                        sx={{
                                            margin: '16px',
                                            color: theme.colors.red,
                                        }}
                                    >
                                        Bitte laden Sie eine valide credentials.json im Tab "Einstellungen" hoch.
                                    </Typography>
                                    <Grid
                                        container
                                        style={{justifyContent: 'center'}}
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenNoCredentialsFound(
                                                        false
                                                    )
                                                }}
                                            >
                                                Verstanden
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
            <Modal
                open={openError}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <ErrorLogo
                                        style={{width: '5em', height: '5em'}}
                                    />
                                    <Typography
                                        sx={{
                                            margin: '16px',
                                            color: theme.colors.red,
                                        }}
                                    >
                                        {errorMessage.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br/>
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                    <Grid
                                        container
                                        style={{justifyContent: 'center'}}
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenError(false)
                                                }}
                                            >
                                                Verstanden
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>

            <Modal
                open={openErrorOnlyNumbersAllowed}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <Typography
                                        sx={{
                                            margin: '16px',
                                            color: theme.colors.red,
                                        }}
                                    >
                                        Die Produkt-Informations-ID darf nur Zahlen enthalten.
                                    </Typography>
                                    <Grid
                                        container
                                        style={{justifyContent: 'center'}}
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenErrorOnlyNumbersAllowed(false)
                                                }}
                                            >
                                                Verstanden
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
        </>
    )
}
