import { SnackbarKey, useSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/material/SvgIcon/SvgIcon'
import * as React from 'react'
import { Fragment, useEffect, useState } from 'react'

const useNotification = () => {
    const [conf, setConf] = useState({})
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const action = (key: SnackbarKey | undefined) => (
        <Fragment>
            <IconButton
                onClick={() => {
                    closeSnackbar(key)
                }}
            >
                <CloseIcon />
            </IconButton>
        </Fragment>
    )
    // eslint-disable-next-line
    useEffect(() => {
        // @ts-ignore
        if (conf?.msg) {
            let variant = 'info'
            // @ts-ignore
            if (conf.variant) {
                // @ts-ignore
                variant = conf.variant
            }
            // @ts-ignore
            enqueueSnackbar(conf.msg, {
                // @ts-ignore
                variant: variant,
                autoHideDuration: 7000,
                action,
            })
            setConf({})
        }
    })
    return [conf, setConf]
}

export default useNotification
