import * as React from 'react'
import {useState} from 'react'
import {styled, Theme} from '@mui/material/styles'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import IconButton, {IconButtonProps} from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {ReactComponent as ErrorLogo} from '../assets/icon.error-circle.svg'
import {ReactComponent as CheckLogo} from '../assets/icon.check-circle.svg'

import {makeStyles, useTheme} from '@mui/styles'
import {SummaryDTO} from '../typings/summary.d'
import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material'

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean
}

const useStyles = makeStyles((theme: any) => ({
    errorBadge: {
        color: theme.colors.red,
        fontSize: 'smaller',
        marginLeft: '0.5em',
    },
    checkBadge: {
        color: theme.colors.green,
        fontSize: 'smaller',
        marginLeft: '0.5em',
    },
    typeDesc: {
        fontWeight: 'bold',
        fontSize: 'large',
        marginTop: '-0.2em',
        marginRight: '0.5em',
    },
    typeDescSuccessfull: {
        fontWeight: 'bold',
        fontSize: 'large',
        marginTop: '-0.2em',
        marginRight: '0.5em',
        marginBottom: '0.8em',
    },
    production: {
        background: theme.colors.red,
        color: 'white',
        marginLeft: '1em',
        fontSize: 'x-small',
        height: '1.7em',
        marginTop: '0.3em',
    },
    card: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '1px solid ' + theme.colors.grey,
        borderRadius: 'unset',
        marginTop: '1em',
    },
    collapseIcon: {
        marginLeft: 'auto',
        marginTop: '-1em',
    },
    select: {
        border: 'none',
        boxShadow: 'none',
        borderRadius: 'unset',
        '& ul': {
            borderRadius: 'unset',
            paddingTop: '0',
            paddingBottom: '0',
            backgroundColor: '#e2e5e7',
            boxShadow: 'none',
        },
    },
    menuItem: {
        '&:hover': {
            color: theme.colors.red + '!important',
            textDecorationLine: 'underline',
            textDecorationStyle: 'solid',
            textDecorationColor: theme.colors.red,
        },
    },
    root: {
        '&::-webkit-scrollbar': {
            width: 8,
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            outline: `1px slategrey`,
        },
    },
}))

interface IProps {
    failed: Array<SummaryDTO>
    succesful: Array<SummaryDTO>
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props
    return <IconButton {...other} />
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

export const RecipeReviewCard: React.FC<IProps> = (props) => {
    const {failed, succesful} = props
    const [expanded, setExpanded] = useState({})
    const classes = useStyles()
    const theme: Theme = useTheme()

    const [filter, setFilter] = React.useState('Standard')

    const handleChange = (event: SelectChangeEvent) => {
        setFilter(event.target.value)
        if (event.target.value === 'Standard') {
            failed.sort(function (a, b) {
                return a.row - b.row
            })
            succesful.sort(function (a, b) {
                return a.row - b.row
            })
        } else if (event.target.value === 'Datum') {
            failed.sort(function (a, b) {
                let test1 = a.date.split('-').join('')
                let test2 = b.date.split('-').join('')
                return test1 < test2 ? 1 : test1 > test2 ? -1 : 0
                // return a.localeCompare(b);         // <-- alternative
            })
            succesful.sort(function (a, b) {
                let test1 = a.date.split('-').join('')
                let test2 = b.date.split('-').join('')
                return test1 < test2 ? 1 : test1 > test2 ? -1 : 0
                // return a.localeCompare(b);         // <-- alternative
            })
        } else if (event.target.value === 'DRPI-Typ') {
            failed.sort((a, b) => a.drpiType.localeCompare(b.drpiType))
            succesful.sort((a, b) => a.drpiType.localeCompare(b.drpiType))
        } else if (event.target.value === 'Produktname') {
            failed.sort((a, b) => a.productname.localeCompare(b.productname))
            succesful.sort((a, b) => a.productname.localeCompare(b.productname))
        }
    }
    console.log(failed, succesful)
    const handleExpandClick = (id) => {
        setExpanded((expanded) => ({
            ...expanded,
            [id]: !expanded[id],
        }))
    }

    function getDateString(date: string) {
        const [year, month, day] = date.split('-')
        return `${day}.${month}.${year}`
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    float: 'right',
                    marginRight: '-0.5em',
                    marginTop: '-1em',
                }}
            >
                <p>Sortieren nach:</p>
                <FormControl sx={{m: 1, minWidth: 160}} size="small">
                    <Select
                        id="demo-select-small"
                        value={filter}
                        onChange={handleChange}
                        MenuProps={{
                            classes: {
                                paper: classes.select,
                            },
                        }}
                        sx={{
                            color: '#003064',
                            backgroundColor: '#e2e5e7',
                            borderRadius: 'unset',
                            '.MuiOutlinedInput-notchedOutline': {border: 0},
                            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                                {
                                    border: 0,
                                },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                {
                                    border: 0,
                                },
                            '&.MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
                                borderRadius: 'unset',
                            },
                        }}
                    >
                        <MenuItem
                            style={{color: '#003064'}}
                            className={classes.menuItem}
                            value={'Standard'}
                        >
                            Standard
                        </MenuItem>
                        <MenuItem
                            style={{color: '#003064'}}
                            className={classes.menuItem}
                            value={'Datum'}
                        >
                            Datum
                        </MenuItem>
                        <MenuItem
                            style={{color: '#003064'}}
                            className={classes.menuItem}
                            value={'DRPI-Typ'}
                        >
                            DRPI-Typ
                        </MenuItem>
                        <MenuItem
                            style={{color: '#003064'}}
                            className={classes.menuItem}
                            value={'Produktname'}
                        >
                            Produktname
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div
                style={{height: '40vh', overflowY: 'auto', marginTop: '6em'}}
                className={classes.root}
            >
                {failed.length > 0 && (
                    <>
                        <h2
                            style={{
                                color: theme.colors.red,
                                marginTop: 'unset',
                            }}
                        >
                            Probleme
                        </h2>
                        {failed.map((summary: SummaryDTO) => {
                            return (
                                <div className={classes.card}>
                                    <div style={{display: 'flex'}}>
                                        <ErrorLogo
                                            style={{
                                                marginTop: '0.17em',
                                                width: '1em',
                                                height: '1em',
                                            }}
                                        />
                                        <div
                                            style={{
                                                marginLeft: '1em',
                                                fontSize: 'smaller',
                                            }}
                                        >
                                            {getDateString(summary.date)}
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: '0.5em',
                                                fontSize: 'medium',
                                                marginTop: '-0.20em',
                                            }}
                                        >
                                            {' | '}
                                        </div>
                                        {summary.drpiType ? <div className={classes.errorBadge}>
                                            {summary.drpiType}
                                        </div> : <div className={classes.errorBadge}>
                                            {'kein DRPI-Typ'}
                                        </div>}

                                        <div
                                            style={{
                                                marginLeft: '0.5em',
                                                fontSize: 'medium',
                                                marginTop: '-0.20em',
                                            }}
                                        >
                                            {' | Reihe'}
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: '0.5em',
                                                fontSize: 'medium',
                                                marginTop: '-0.20em',
                                            }}
                                        >
                                            {summary.row}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: '0.5em',
                                        }}
                                    >
                                        <div className={classes.typeDesc}>
                                            {summary.productname}
                                        </div>
                                        <div className={classes.production}>
                                            <p
                                                style={{
                                                    marginTop: '0',
                                                    paddingLeft: '0.5em',
                                                    paddingRight: '0.5em',
                                                }}
                                            >{`${summary.errors.length} Mapping-Fehler`}</p>
                                        </div>
                                        <CardActions
                                            className={classes.collapseIcon}
                                            disableSpacing
                                        >
                                            <ExpandMore
                                                expand={expanded[summary.row]}
                                                onClick={() =>
                                                    handleExpandClick(
                                                        summary.row
                                                    )
                                                }
                                                aria-expanded={
                                                    expanded[summary.row]
                                                }
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon/>
                                            </ExpandMore>
                                        </CardActions>
                                    </div>

                                    <div>
                                        <Collapse
                                            in={expanded[summary.row]}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <ul
                                                style={{
                                                    fontSize: 'small',
                                                    marginTop: '-0.5em',
                                                }}
                                            >
                                                {summary.errors.slice(0, 11).map(
                                                    (error: string, index) => {
                                                        if (index >= 10) {
                                                            return <span
                                                                style={{paddingLeft: '1em'}}>und {summary.errors.length - 10} weitere/r Fehler</span>
                                                        } else {
                                                            return <li>{error}</li>
                                                        }

                                                    }
                                                )}
                                            </ul>
                                        </Collapse>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )}

                {succesful.length > 0 && (
                    <>
                        <h2
                            style={{
                                color: theme.colors.green,
                                marginBottom: 'unset',
                                marginTop: failed.length > 0 ? '1em' : '0em',
                            }}
                        >
                            Erfolgreich
                        </h2>
                        {succesful.map((summary: SummaryDTO) => {
                            return (
                                <div className={classes.card}>
                                    <div style={{display: 'flex'}}>
                                        <CheckLogo
                                            style={{
                                                marginTop: '0.17em',
                                                width: '1em',
                                                height: '1em',
                                            }}
                                        />
                                        <div
                                            style={{
                                                marginLeft: '1em',
                                                fontSize: 'smaller',
                                            }}
                                        >
                                            {getDateString(summary.date)}
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: '0.5em',
                                                fontSize: 'medium',
                                                marginTop: '-0.25em',
                                            }}
                                        >
                                            {' | '}
                                        </div>
                                        {summary.drpiType ? <div className={classes.checkBadge}>
                                            {summary.drpiType}
                                        </div> : <div className={classes.errorBadge}>
                                            {'kein DRPI-Typ'}
                                        </div>}

                                        <div
                                            style={{
                                                marginLeft: '0.5em',
                                                fontSize: 'medium',
                                                marginTop: '-0.20em',
                                            }}
                                        >
                                            {' | Reihe'}
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: '0.5em',
                                                fontSize: 'medium',
                                                marginTop: '-0.20em',
                                            }}
                                        >
                                            {summary.row}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            marginTop: '0.5em',
                                        }}
                                    >
                                        <div
                                            className={
                                                classes.typeDescSuccessfull
                                            }
                                        >
                                            {summary.productname}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                )}
            </div>
        </>
    )
}
