import * as React from 'react'
import {useState} from 'react'

import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import {Header} from '../components/Header'
import {makeStyles, useTheme} from '@mui/styles'
import {Backdrop, Box, Button, CircularProgress, Grid, Modal, Tab, Tabs, Typography,} from '@mui/material'
import {$api} from '../api/callApi'
import {ReactComponent as ErrorLogo} from '../assets/icon.file-error.svg'
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
    textArea: {
        maxHeight: '530px',
        width: '100em',
        maxWidth: '100em',
    },
    modal: {
        width: 'auto',
        padding: '2em 2em 2em',
        marginTop: '25vh',
        background: theme.colors.greyLight,
    },
}))

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

export const ReferenceDataPage = () => {
    const theme: Theme = useTheme()

    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const [genericWsdl, setGenericWsdl] = useState('')
    const [ecoWsdl, setEcoWsdl] = useState('')
    const [elbWsdl, setElbWsdl] = useState('')
    const [emcWsdl, setEmcWsdl] = useState('')
    const [clpWsdl, setClpWsdl] = useState('')
    const [cprWsdl, setCprWsdl] = useState('')
    const [rchWsdl, setRchWsdl] = useState('')
    const [redWsdl, setRedWsdl] = useState('')
    const [wsdlsAlreadyLoaded, setWsdlsAlreadyLoaded] = useState(false)
    const [openNoCredentialsFound, setOpenNoCredentialsFound] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [openError, setOpenError] = useState(false)

    let finishedLoadedWsdlList: Array<string> = []
    const handleCloseBackdropClick = () => {
        setOpenNoCredentialsFound(false)
    }
    const [open, setOpen] = React.useState(false)

    function throwException(error: any) {
        if (!openError) {
            setOpenError(true)
            setErrorMessage(error.response.data.message)
            setOpen(false)
        }
    }

    if (!wsdlsAlreadyLoaded) {
        $api.mapping
            .getCredentialsJson()
            .then((resp) => {
                if (resp.data) {
                    setOpen(true)
                    $api.vif
                        .getReferenceDataFromDrpiType('GENERIC')
                        .then((resp) => {
                            finishedLoadedWsdlList.push('GENERIC')
                            setGenericWsdl(prettifyXml(resp.data))
                            checkIfAllWsdlsLoaded()
                        })
                        .catch((error) => {
                            throwException(error)
                        })
                    $api.vif
                        .getReferenceDataFromDrpiType('ECO')
                        .then((resp) => {
                            finishedLoadedWsdlList.push('ECO')
                            setEcoWsdl(prettifyXml(resp.data))
                            checkIfAllWsdlsLoaded()
                        })
                        .catch((error) => {
                            throwException(error)
                        })
                    $api.vif
                        .getReferenceDataFromDrpiType('ELB')
                        .then((resp) => {
                            finishedLoadedWsdlList.push('ELB')
                            setElbWsdl(prettifyXml(resp.data))
                            checkIfAllWsdlsLoaded()
                        })
                        .catch((error) => {
                            throwException(error)
                        })
                    $api.vif
                        .getReferenceDataFromDrpiType('EMC')
                        .then((resp) => {
                            finishedLoadedWsdlList.push('EMC')
                            setEmcWsdl(prettifyXml(resp.data))
                            checkIfAllWsdlsLoaded()
                        })
                        .catch((error) => {
                            throwException(error)
                        })
                    $api.vif
                        .getReferenceDataFromDrpiType('CLP')
                        .then((resp) => {
                            finishedLoadedWsdlList.push('CLP')
                            setClpWsdl(prettifyXml(resp.data))
                            checkIfAllWsdlsLoaded()
                        })
                        .catch((error) => {
                            throwException(error)
                        })
                    $api.vif
                        .getReferenceDataFromDrpiType('CPR')
                        .then((resp) => {
                            finishedLoadedWsdlList.push('CPR')
                            setCprWsdl(prettifyXml(resp.data))
                            checkIfAllWsdlsLoaded()
                        })
                        .catch((error) => {
                            throwException(error)
                        })
                    $api.vif
                        .getReferenceDataFromDrpiType('RCH')
                        .then((resp) => {
                            finishedLoadedWsdlList.push('RCH')
                            setRchWsdl(prettifyXml(resp.data))
                            checkIfAllWsdlsLoaded()
                        })
                        .catch((error) => {
                            throwException(error)
                        })
                    $api.vif
                        .getReferenceDataFromDrpiType('RED')
                        .then((resp) => {
                            finishedLoadedWsdlList.push('RED')
                            setRedWsdl(prettifyXml(resp.data))
                            checkIfAllWsdlsLoaded()
                        })
                        .catch((error) => {
                            throwException(error)
                        })
                } else {
                    setOpenNoCredentialsFound(true)
                }
            })

        setWsdlsAlreadyLoaded(true)
    }

    function checkIfAllWsdlsLoaded() {
        if (
            finishedLoadedWsdlList.includes('GENERIC') &&
            finishedLoadedWsdlList.includes('ECO') &&
            finishedLoadedWsdlList.includes('ELB') &&
            finishedLoadedWsdlList.includes('EMC') &&
            finishedLoadedWsdlList.includes('CLP') &&
            finishedLoadedWsdlList.includes('CPR') &&
            finishedLoadedWsdlList.includes('RCH') &&
            finishedLoadedWsdlList.includes('RED')
        ) {
            setOpen(false)
        }
    }

    function CustomTabPanel(props: TabPanelProps) {
        const {children, value, index, ...other} = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{p: 3}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        )
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    function prettifyXml(xml: string) { // tab = optional indent value, default is tab (\t)
        var formatted = '', indent = '';
        var tab = '\t';
        xml.split(/>\s*</).forEach(function (node) {
            if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
            formatted += indent + '<' + node + '>\r\n';
            // eslint-disable-next-line
            if (node.match(/^<?\w[^>]*[^\/]$/)) indent += tab;              // increase indent
        });
        return formatted.substring(1, formatted.length - 3);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <>
            <CssBaseline/>
            <Header/>
            <div>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </div>
            <Container sx={{ml: 'auto', mr: 'auto'}} component="main">
                <Grid container justifyContent="center" spacing={1} style={{marginTop: "0.1em"}}>
                    <h1 style={{fontWeight: 'lighter'}}>
                        Referenzdaten-WSDL
                    </h1>
                </Grid>
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="GENERIC" {...a11yProps(0)} />
                            <Tab label="ECO" {...a11yProps(1)} />
                            <Tab label="ELB" {...a11yProps(2)} />
                            <Tab label="EMC" {...a11yProps(3)} />
                            <Tab label="CLP" {...a11yProps(4)} />
                            <Tab label="CPR" {...a11yProps(5)} />
                            <Tab label="RCH" {...a11yProps(6)} />
                            <Tab label="RED" {...a11yProps(7)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Grid
                            container
                            justifyContent="center"
                            mt={3}
                            spacing={1}
                        >
                            <textarea
                                readOnly={true}
                                className={classes.textArea}
                                rows={35}
                                defaultValue={genericWsdl}
                            />
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Grid
                            container
                            justifyContent="center"
                            mt={3}
                            spacing={1}
                        >
                            <textarea
                                readOnly={true}
                                className={classes.textArea}
                                rows={35}
                                defaultValue={ecoWsdl}
                            />
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Grid
                            container
                            justifyContent="center"
                            mt={3}
                            spacing={1}
                        >
                            <textarea
                                readOnly={true}
                                className={classes.textArea}
                                rows={35}
                                defaultValue={elbWsdl}
                            />
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <Grid
                            container
                            justifyContent="center"
                            mt={3}
                            spacing={1}
                        >
                            <textarea
                                readOnly={true}
                                className={classes.textArea}
                                rows={35}
                                defaultValue={emcWsdl}
                            />
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        <Grid
                            container
                            justifyContent="center"
                            mt={3}
                            spacing={1}
                        >
                            <textarea
                                readOnly={true}
                                className={classes.textArea}
                                rows={35}
                                defaultValue={clpWsdl}
                            />
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={5}>
                        <Grid
                            container
                            justifyContent="center"
                            mt={3}
                            spacing={1}
                        >
                            <textarea
                                readOnly={true}
                                className={classes.textArea}
                                rows={35}
                                defaultValue={cprWsdl}
                            />
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={6}>
                        <Grid
                            container
                            justifyContent="center"
                            mt={3}
                            spacing={1}
                        >
                            <textarea
                                readOnly={true}
                                className={classes.textArea}
                                rows={35}
                                defaultValue={rchWsdl}
                            />
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={7}>
                        <Grid
                            container
                            justifyContent="center"
                            mt={3}
                            spacing={1}
                        >
                            <textarea
                                readOnly={true}
                                className={classes.textArea}
                                rows={35}
                                defaultValue={redWsdl}
                            />
                        </Grid>
                    </CustomTabPanel>
                </Box>
            </Container>
            <Modal
                open={openNoCredentialsFound}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <ErrorLogo
                                        style={{width: '5em', height: '5em'}}
                                    />
                                    <Typography
                                        sx={{
                                            margin: '16px',
                                            color: theme.colors.red,
                                        }}
                                    >
                                        Bitte laden Sie eine valide credentials.json im Tab "Einstellungen" hoch.
                                    </Typography>
                                    <Grid
                                        container
                                        style={{justifyContent: 'center'}}
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenNoCredentialsFound(
                                                        false
                                                    )
                                                }}
                                            >
                                                Verstanden
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
            <Modal
                open={openError}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <ErrorLogo
                                        style={{width: '5em', height: '5em'}}
                                    />
                                    <Typography
                                        sx={{
                                            margin: '16px',
                                            color: theme.colors.red,
                                        }}
                                    >
                                        {errorMessage.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br/>
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                    <Grid
                                        container
                                        style={{justifyContent: 'center'}}
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenError(false)
                                                }}
                                            >
                                                Verstanden
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
        </>
    )
}
