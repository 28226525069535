import { createTheme, Shadows } from '@mui/material/styles'

declare module '@mui/material/styles' {
    interface Theme {
        colors: {
            white: string
            blue: string
            green: string
            grey: string
            greyLight: string
            red: string
            redLight: string
            redDark: string
        }
        fonts: string[]
        fontSizes: {
            small: string
            medium: string
            bigger: string
            large: string
        }
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        colors?: {
            white?: string
            blue?: string
            green?: string
            grey?: string
            greyLight?: string
            red?: string
            redLight?: string
        }
        fonts?: string[]
        fontSizes?: {
            small?: string
            medium?: string
            bigger?: string
            large?: string
        }
    }
}

const themeColors: any = {
    white: '#FFFFFF',
    blue: '#003064',
    green: '#1EAE9C',
    grey: '#4A4A4A4D',
    greyLight: '#F9FAFB',
    red: '#D60D4C',
    redLight: '#d60d4c4d',
    redDark: '#95094B',
}
const themeFonts: any = ['Muli']
const themeFontSizes: any = {
    small: '0.90em',
    medium: '1.20em',
    bigger: '1.85em',
    large: '2.75em',
}

export const materialTheme = createTheme({
    colors: themeColors,
    shadows: Array(25).fill('none') as Shadows,
    shape: {
        borderRadius: 0,
    },
    fonts: themeFonts,
    fontSizes: themeFontSizes,
    typography: {
        // Tell Material UI what the font-size on the html element is.
        htmlFontSize: 17,
        fontFamily: themeFonts[0],
    },
    palette: {
        primary: {
            main: themeColors.blue,
            // light: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: themeColors.red,
            // main
            // dark: will be calculated from palette.secondary.main,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    backgroundColor: themeColors.white,
                    fontFamily: themeFonts[0],
                    color: themeColors.blue,
                    '& h1': {
                        fontSize: themeFontSizes.large,
                    },
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    backgroundColor: 'red',
                    color: 'red',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: themeColors.white,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: themeColors.red,
                    '&.Mui-selected': {
                        color: themeColors.red,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '5px 15px',
                    borderRadius: '0',
                    textTransform: 'initial',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    border: `1px solid ${themeColors.red}`,
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                        {
                            border: 0,
                        },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                        {
                            border: 0,
                        },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    padding: '5%',
                    // overflow: "scroll",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {},
            },
        },
    },
})
