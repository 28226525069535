import React, {useCallback, useEffect, useState} from 'react'
import {Header} from '../components/Header'
import {Box, Button, Container, Grid, LinearProgress, Modal, Typography,} from '@mui/material'
import {makeStyles, useTheme} from '@mui/styles'
import {useDropzone} from 'react-dropzone'
import {Theme} from '@mui/material/styles'
import {VisSummary} from '../components/visSummary'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {$api} from '../api/callApi'
import {SummaryDTO} from '../typings/summary.d'
import {ReactComponent as ErrorLogo} from '../assets/icon.file-error.svg'
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
    dragActive: {
        background: theme.colors.redLight,
        border: '2px dashed ' + theme.colors.blue,
    },
    drag: {
        border: '2px dashed ' + theme.colors.blue,
    },
    modal: {
        width: '50vw',
        padding: '0em 2em 2em',
        marginTop: '25vh',
        background: theme.colors.greyLight,
    },
    rootDropzone: {
        width: '50vw',
        padding: '2em',
        borderTop: '4px solid ' + theme.colors.red,
        background: theme.colors.greyLight,
    },
    errorIcon: {
        color: 'red',
        transform: 'rotate(45deg)',
        marginBottom: '-5px',
        marginRight: '5px',
    },
}))

export const OverviewPage = () => {
    const theme: Theme = useTheme()
    const {t} = useTranslation()
    const [openModal, setOpenModal] = useState(false)
    const [progress, setProgress] = useState(0)
    const [summary, setSummary] = useState<SummaryDTO[]>([])
    const [uuid, setUuid] = useState<string>()
    const [errorMessage, setErrorMessage] = useState('')
    const [errorModal, setErrorModal] = useState(false)

    const classes = useStyles()

    const startLoading = () => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    setOpenModal(false)
                    // return 0;
                }
                const diff = Math.random() * 10
                return Math.min(oldProgress + diff, 100)
            })
        }, 100)

        return () => {
            clearInterval(timer)
        }
    }

    const onDrop = useCallback((droppedFile: any) => {
        if (droppedFile.length > 0) {
            checkIfSessionIsActive()
            setOpenModal(true)
            startLoading()
            $api.vif
                .postVifFile(droppedFile)
                .then((response) => response.data)
                .then((data) => {
                    setUuid(data.uuid)
                    setSummary(data.summaries)
                })
                .catch((error) => {
                    if (
                        error.message !== "Network Error"
                    ) {
                        setErrorMessage(error.response.data.message)
                        setErrorModal(true)
                        setOpenModal(false)
                    }
                })
        }
        //eslint-disable-next-line
    }, [])

    const handleCloseBackdropClick = () => {
        setOpenModal(false)
    }

    const {getRootProps, getInputProps, fileRejections, isDragActive} =
        useDropzone({
            onDrop,
            accept: {
                'text/x.vif': ['.vif'],
            },
            multiple: false,
        })

    function removeUserFromLocalStorageAndRedirectToLogin() {
        localStorage.removeItem('userRole')
        //@ts-ignore
        localStorage.setItem('manuallyLogout', false)
        window.location.href = '/login'
    }

    function checkIfSessionIsActive() {
        $api.userRole
            .getCurrentUserRole()
            .then((resp) => {
                //Das ist der Fall wenn die Session abgelaufen ist, returned spring ein "whiteable error html doc" was bedeutet dass die Session abgelaufen ist
                if (isHtml(resp.data)) {
                    removeUserFromLocalStorageAndRedirectToLogin()
                }
            })
            .catch((error) => {
                removeUserFromLocalStorageAndRedirectToLogin()
            })
    }

    const isHtml = RegExp.prototype.test.bind(/(<([^>]+)>)/i)
    useEffect(() => {
        checkIfSessionIsActive();
        //eslint-disable-next-line
    }, [t])


    return (
        <>
            <Header/>
            {(progress !== 100 || summary.length === 0) ? (
                <Container style={{paddingTop: '4em'}} maxWidth={false}>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <h1 style={{fontWeight: 'lighter'}}>
                            VIF-Datei hochladen
                        </h1>
                    </Grid>

                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div
                            className={classes.rootDropzone}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <div
                                className={
                                    isDragActive
                                        ? classes.dragActive
                                        : classes.drag
                                }
                            >
                                <Grid
                                    container
                                    direction="row"
                                    mb={5}
                                    mt={2}
                                    justifyContent="center"
                                    spacing={4}
                                >
                                    <Grid textAlign={'center'} item xs={12}>
                                        <h4
                                            style={{
                                                margin: '16px',
                                                fontWeight: 'lighter',
                                            }}
                                        >
                                            Datei für den Upload reinziehen
                                        </h4>
                                        <Typography
                                            style={{
                                                fontSize: '10px',
                                                margin: '0 16px',
                                            }}
                                        >
                                            oder
                                        </Typography>

                                        <Button
                                            sx={{
                                                m: 2,
                                                backgroundColor:
                                                theme.colors.white,
                                                border:
                                                    '1px solid ' +
                                                    theme.colors.blue,
                                                '&:hover': {
                                                    backgroundColor:
                                                        theme.colors.blue +
                                                        '!important',
                                                    color: 'white',
                                                },
                                            }}
                                        >
                                            VIF-Datei auswählen
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    {fileRejections.length > 0 && (
                        <Grid
                            container
                            justifyContent="center"
                            direction="row"
                            mb={5}
                            mt={2}
                            spacing={1}
                        >
                            <Typography fontWeight={'bold'}>
                                <AddCircleIcon className={classes.errorIcon}/>{' '}
                                Fehler beim hochladen:{' '}
                            </Typography>
                            <Typography style={{marginLeft: '5px'}}>
                                {' '}
                                Die hochgeladene Datei muss eine VIF-Datei sein!{' '}
                            </Typography>
                        </Grid>
                    )}
                </Container>
            ) : (
                <VisSummary summary={summary} uuid={uuid}/>
            )}
            <Modal open={openModal} onClose={handleCloseBackdropClick}>
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={5}
                                mt={2}
                                justifyContent="center"
                                spacing={4}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <Typography style={{margin: '16px'}}>
                                        Datensätze werden hochgeladen
                                    </Typography>
                                    <Box sx={{width: '100%'}}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={progress}
                                            color={'primary'}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
            <Modal
                open={errorModal}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <ErrorLogo
                                        style={{width: '5em', height: '5em'}}
                                    />
                                    <Typography
                                        sx={{
                                            margin: '16px',
                                            color: theme.colors.red,
                                        }}
                                    >
                                        {errorMessage.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br/>
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                    <Grid
                                        container
                                        style={{justifyContent: 'center'}}
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setErrorModal(
                                                        false
                                                    )
                                                }}
                                            >
                                                Verstanden
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
        </>
    )
}
