import './App.css'
import {materialTheme} from './Theme'
import {CssBaseline, ThemeProvider as MaterialThemeProvider,} from '@mui/material'
import {Navigate, Route, Routes} from 'react-router-dom'
import {OverviewPage} from './pages/OverviewPage'
import {Login} from './pages/Login'
import {SettingsPage} from './pages/SettingsPage'
import {ReferenceDataPage} from './pages/ReferenceDataPage'
import {PiDrpiDataPage} from "./pages/PiDrpiDataPage";

export const process = (value: string) => {
    let string = value
    string.replace(/\u00dc/g, 'Ue')
    string.replace(/\u00fc/g, 'ue')
    string.replace(/\u00c4/g, 'Ae')
    string.replace(/\u00e4/g, 'ae')
    string.replace(/\u00d6/g, 'Oe')
    string.replace(/\u00f6/g, 'oe')
    string.replace(/\u00df/g, 'ss')
    return string === undefined
        ? ''
        : string
            .replace(/[^a-z0-9_]+/gi, '')
            .replace(/^-|-$/g, '')
            .toLowerCase()
}

export const App = () => {
    const logedInUser = localStorage.getItem('userRole')
    const redirectToStartPageFromSettingIfNotAdmin = () => {
        if (!logedInUser) {
            return (
                <>
                    <Navigate to="/login"/>
                </>
            )
        }
        if (logedInUser === 'ROLE_USER') {
            return (
                <>
                    <Navigate to="/"/>
                </>
            )
        } else {
            return (
                <>
                    <SettingsPage/>
                </>
            )
        }
    }
    const redirectToStartPageFromReferenceDataIfNotAdmin = () => {
        if (!logedInUser) {
            return (
                <>
                    <Navigate to="/login"/>
                </>
            )
        }
        if (logedInUser === 'ROLE_USER') {
            return (
                <>
                    <Navigate to="/"/>
                </>
            )
        } else {
            return (
                <>
                    <ReferenceDataPage/>
                </>
            )
        }
    }
    const redirectToStartPageFromPiDrpiDataIfNotAdmin = () => {
        if (!logedInUser) {
            return (
                <>
                    <Navigate to="/login"/>
                </>
            )
        }
        if (logedInUser === 'ROLE_USER') {
            return (
                <>
                    <Navigate to="/"/>
                </>
            )
        } else {
            return (
                <>
                    <PiDrpiDataPage/>
                </>
            )
        }
    }
    return (
        <>
            <MaterialThemeProvider theme={materialTheme}>
                <CssBaseline/>
                <Routes>
                    <Route
                        path="/login"
                        element={logedInUser ? <Navigate to="/"/> : <Login/>}
                    />
                    <Route
                        path="/"
                        element={
                            !logedInUser ? (
                                <Navigate to="/login"/>
                            ) : (
                                <OverviewPage/>
                            )
                        }
                    ></Route>
                    <Route
                        path="/settings"
                        element={redirectToStartPageFromSettingIfNotAdmin()}
                    ></Route>
                    <Route
                        path="/referencedata"
                        element={redirectToStartPageFromReferenceDataIfNotAdmin()}
                    ></Route>
                    <Route
                        path="/pidrpidata"
                        element={redirectToStartPageFromPiDrpiDataIfNotAdmin()}
                    ></Route>
                </Routes>
            </MaterialThemeProvider>
        </>
    )
}
