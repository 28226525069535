import {Backdrop, Button, CircularProgress, Container, Grid, Modal, Typography} from '@mui/material'
import React, {useState} from 'react'
import {makeStyles, useTheme} from '@mui/styles'
import {Theme} from '@mui/material/styles'
import EastIcon from '@mui/icons-material/East'
import {RecipeReviewCard} from './summaryTable'
import {SummaryDTO} from '../typings/summary.d'
import {ReactComponent as ArrowRight} from '../assets/icon.arrow-right.svg'
import {$api} from "../api/callApi";
import {ReactComponent as ErrorLogo} from '../assets/icon.file-error.svg'


interface IProps {
    summary: Array<SummaryDTO>,
    uuid: string
}

const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        width: '40vw',
        padding: '0em 2em 2em',
        marginTop: '25vh',
        background: theme.colors.greyLight,
    },
    biggerModal: {
        width: '45vw',
        padding: '0em 2em 2em',
        marginTop: '25vh',
        background: theme.colors.greyLight,
    },
    summary: {
        width: '80vw',
        padding: '2em',
        borderTop: '4px solid ' + theme.colors.red,
        background: theme.colors.greyLight,
    },
    errorIcon: {
        color: 'red',
        transform: 'rotate(45deg)',
        marginBottom: '-5px',
    },
    checkmarkCircle: {
        strokeDasharray: '166',
        strokeDashoffset: '166',
        strokeWidth: 2,
        strokeMiterlimit: 10,
        stroke: theme.colors.green,
        fill: 'none',
        animation: `$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards`,
    },
    checkmark: {
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        display: 'block',
        strokeWidth: 2,
        stroke: '#fff',
        strokeMiterlimit: 10,
        margin: '0 auto',
        boxShadow: 'inset 0px 0px 0px ' + theme.colors.green,
        animation: `$fill .4s ease-in-out .4s forwards, $scale .3s ease-in-out .9s both`,
    },
    checkmarkCheck: {
        transformOrigin: '50% 50%',
        strokeDasharray: 48,
        strokeDashoffset: 48,
        animation: `$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards`,
    },
    '@keyframes stroke': {
        '100%': {
            strokeDashoffset: 0,
        },
    },
    '@keyframes scale': {
        '0%, 100%': {
            transform: 'none',
        },
        '50%': {
            transform: 'scale3d(1.1, 1.1, 1)',
        },
    },
    '@keyframes fill': {
        '100%': {
            boxShadow: 'inset 0px 0px 0px 30px ' + theme.colors.green,
        },
    },
    marginTopAndBotUnset: {
        marginTop: '-0.3em',
        marginBottom: 'unset',
        marginLeft: '0.3em',
    },
    summaryTextInBoxes: {
        fontSize: '11px',
        marginLeft: '1.3em',
        marginTop: '-0.4em',
    },
    root: {
        '&::-webkit-scrollbar': {
            width: 10,
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            outline: `1px solid slategrey`,
        },
    },
}))

export const VisSummary: React.FC<IProps> = (props) => {
    const {summary, uuid} = props
    const classes = useStyles()
    const theme: Theme = useTheme()
    const [openModal, setOpenModal] = useState(false)
    const [newStartModal, setNewStartModal] = useState(false)
    const [open, setOpen] = React.useState(false)
    const [succesfullSendToIcsmsModal, setSuccesfullSendToIcsmsModal] = useState(false)
    const [succesfullSendToIcsmsList, setSuccesfullSendToIcsmsList] = useState([])
    const [errorMessage, setErrorMessage] = useState('')
    const [openError, setOpenError] = useState(false)

    const handleCloseBackdropClick = () => {
        setOpenModal(false)
        setNewStartModal(false)
    }

    function throwException(error: any) {
        if (!openError) {
            setOpenError(true)
            setErrorMessage(error.response.data.message)
            setOpen(false)
        }
    }

    return (
        <>
            <div>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={open}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </div>
            <Container className={classes.root} maxWidth={false}>
                <Grid container justifyContent="center" mt={1} spacing={1}>
                    <h1
                        style={{
                            marginTop: '0.1em',
                            marginBottom: 'unset',
                            fontSize: 'xx-large'
                        }}
                    >
                        Prüfung der VIF-Datei
                    </h1>
                </Grid>
                <Grid
                    container
                    justifyContent="center"
                    mt={3}
                    mb={5}
                    spacing={1}
                >
                    <div className={classes.summary}>
                        <Grid
                            container
                            direction="row"
                            mb={5}
                            mt={2}
                            justifyContent="center"
                            style={{height: '4em'}}
                        >
                            <Grid
                                color={'white'}
                                textAlign={'left'}
                                p={1}
                                item
                                xs={true}
                                style={{
                                    background: theme.colors.blue,
                                    marginRight: '2em',
                                    height: '5em',
                                }}
                            >
                                <h1 className={classes.marginTopAndBotUnset}>
                                    {summary?.length}
                                </h1>
                                <div className={classes.summaryTextInBoxes}>
                                    Geprüfte Datensätze
                                </div>
                            </Grid>
                            <Grid
                                color={'white'}
                                textAlign={'left'}
                                p={1}
                                item
                                xs={true}
                                style={{
                                    background: theme.colors.green,
                                    height: '5em',
                                }}
                            >
                                <h1 className={classes.marginTopAndBotUnset}>
                                    {summary?.filter((s) => s.successful).length}
                                </h1>
                                <div className={classes.summaryTextInBoxes}>
                                    Erfolgreiche Datensätze
                                </div>
                            </Grid>
                            {summary?.filter((s) => !s.successful).length >
                                0 && (
                                    <Grid
                                        color={'white'}
                                        textAlign={'left'}
                                        p={1}
                                        item
                                        xs={true}
                                        style={{
                                            background: theme.colors.red,
                                            marginLeft: '2em',
                                            height: '5em',
                                        }}
                                    >
                                        <h1
                                            className={classes.marginTopAndBotUnset}
                                        >
                                            {
                                                summary?.filter((s) => !s.successful)
                                                    .length
                                            }
                                        </h1>
                                        <div className={classes.summaryTextInBoxes}>
                                            Fehlerhafte Datensätze
                                        </div>
                                    </Grid>
                                )}
                        </Grid>

                        <RecipeReviewCard
                            failed={summary?.filter((s) => !s.successful)}
                            succesful={summary?.filter((s) => s.successful)}
                        />

                        <Grid
                            container
                            direction="row"
                            mb={0}
                            mt={2}
                            spacing={0}
                        >
                            <Grid textAlign={'left'} item xs={6}>
                                <Button
                                    sx={{
                                        marginTop: 2,
                                        p: 1,
                                        backgroundColor: theme.colors.white,
                                        border:
                                            '1px solid ' + theme.colors.blue,
                                        '&:hover': {
                                            backgroundColor: theme.colors.blue,
                                            color: theme.colors.white,
                                            cursor: 'pointer',
                                        },
                                    }}
                                    onClick={() => {
                                        setNewStartModal(true)
                                    }}
                                >
                                    Neu starten
                                </Button>
                            </Grid>
                            <Grid textAlign={'right'} item xs={6}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginTop: 2,
                                        p: 1,
                                        backgroundColor: theme.colors.blue,
                                        '&:hover': {
                                            background: theme.colors.red,
                                            cursor: 'pointer',
                                        },
                                    }}
                                    disabled={
                                        summary?.filter((s) => !s.successful)
                                            .length > 0
                                    }
                                    onClick={() => {
                                        setOpenModal(true)
                                    }}
                                >
                                    Daten hochladen
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Container>
            <Modal open={openModal} onClose={handleCloseBackdropClick}>
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <Typography
                                        variant="h5"
                                        style={{margin: '16px'}}
                                    >
                                        Sind Sie sicher, dass Sie die neuen
                                        Datensätze zum ICSMS hochladen möchten?
                                    </Typography>
                                    <Grid
                                        container
                                        direction="row"
                                        mb={0}
                                        mt={6}
                                        spacing={0}
                                    >
                                        <Grid textAlign={'left'} item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenModal(false)

                                                }}
                                            >
                                                Abbrechen
                                            </Button>
                                        </Grid>
                                        <Grid textAlign={'right'} item xs={6}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor:
                                                    theme.colors.red,
                                                }}
                                                onClick={() => {
                                                    setOpen(true)
                                                    setOpenModal(false)
                                                    $api.vif.postRequestsToIcsms(uuid).then((resp) => {
                                                        setOpen(false)
                                                        setSuccesfullSendToIcsmsModal(true)
                                                        setSuccesfullSendToIcsmsList(resp.data);
                                                    }).catch((err) => throwException(err))
                                                }}
                                            >
                                                Ja, ich bin sicher{' '}
                                                <EastIcon
                                                    style={{
                                                        margin: '5px',
                                                        paddingTop: '2px',
                                                        fontSize: '15px',
                                                    }}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
            <Modal
                open={openError}
                onClose={handleCloseBackdropClick}
            >
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <ErrorLogo
                                        style={{width: '5em', height: '5em'}}
                                    />
                                    <Typography
                                        sx={{
                                            margin: '16px',
                                            color: theme.colors.red,
                                        }}
                                    >
                                        {errorMessage.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br/>
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                    <Grid
                                        container
                                        style={{justifyContent: 'center'}}
                                        direction="row"
                                        mb={0}
                                        mt={2}
                                        spacing={0}
                                    >
                                        <Grid item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setOpenError(false)
                                                }}
                                            >
                                                Verstanden
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
            <Modal open={newStartModal} onClose={handleCloseBackdropClick}>
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.modal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <Typography
                                        variant="h5"
                                        style={{margin: '16px'}}
                                    >
                                        Möchten Sie den Vorgang wirklich neu
                                        starten? Alle hochgeladenen Datensätze
                                        gehen dabei verloren.
                                    </Typography>
                                    <Grid
                                        container
                                        direction="row"
                                        mb={0}
                                        mt={6}
                                        spacing={0}
                                    >
                                        <Grid textAlign={'left'} item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setNewStartModal(false)
                                                }}
                                            >
                                                Abbrechen
                                            </Button>
                                        </Grid>
                                        <Grid textAlign={'right'} item xs={6}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor:
                                                    theme.colors.red,
                                                }}
                                                onClick={() => {
                                                    setNewStartModal(false)
                                                }}
                                                href="/"
                                            >
                                                Ja, Vorgang neu starten{' '}
                                                <ArrowRight
                                                    style={{
                                                        marginLeft: '0.5em',
                                                        width: '1em',
                                                        height: '1em',
                                                    }}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>

            <Modal open={succesfullSendToIcsmsModal} onClose={handleCloseBackdropClick}>
                <>
                    <Grid container justifyContent="center" mt={3} spacing={1}>
                        <div className={classes.biggerModal}>
                            <Grid
                                container
                                direction="row"
                                mb={0}
                                mt={2}
                                justifyContent="center"
                                spacing={2}
                            >
                                <Grid textAlign={'center'} item xs={12}>
                                    <div style={{
                                        marginBottom: "2em",
                                        fontWeight: "bold",
                                        fontSize: "large"
                                    }}>Hochladen zu ICSMS
                                        abgeschlossen
                                    </div>
                                    <div style={{display: "flex", marginLeft: "1.2em", marginRight: "1em"}}>
                                        <div
                                            color={'white'}
                                            style={{
                                                background: theme.colors.blue,
                                                marginRight: '2em',
                                                height: '100%',
                                                textAlign: 'left',
                                                padding: 1,
                                                color: "white",
                                                width: "100%"
                                            }}
                                        >
                                            <h1 className={classes.marginTopAndBotUnset}>
                                                {succesfullSendToIcsmsList?.length}
                                            </h1>
                                            <div className={classes.summaryTextInBoxes}>
                                                Hochzuladende Datensätze
                                            </div>
                                        </div>
                                        <div
                                            color={'white'}
                                            style={{
                                                background: theme.colors.green,
                                                height: '100%',
                                                textAlign: 'left',
                                                padding: 1,
                                                color: "white",
                                                width: "100%"
                                            }}
                                        >
                                            <h1 className={classes.marginTopAndBotUnset}>
                                                {succesfullSendToIcsmsList?.filter((s) => s).length}
                                            </h1>
                                            <div className={classes.summaryTextInBoxes}>
                                                Hochgeladene Datensätze
                                            </div>
                                        </div>

                                        <div
                                            color={'white'}
                                            style={{
                                                background: theme.colors.red,
                                                marginLeft: '2em',
                                                height: '100%',
                                                textAlign: 'left',
                                                padding: 1,
                                                color: "white",
                                                width: "100%"
                                            }}
                                        >
                                            <h1
                                                className={classes.marginTopAndBotUnset}
                                            >
                                                {
                                                    succesfullSendToIcsmsList?.filter((s) => !s)
                                                        .length
                                                }
                                            </h1>
                                            <div className={classes.summaryTextInBoxes}>
                                                Fehlgeschlagene Datensätze
                                            </div>
                                        </div>
                                    </div>

                                    <Grid
                                        container
                                        direction="row"
                                        mb={0}
                                        mt={6}
                                        spacing={0}
                                    >
                                        <Grid textAlign={'left'} item xs={6}>
                                            <Button
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor:
                                                    theme.colors.white,
                                                    border:
                                                        '1px solid ' +
                                                        theme.colors.blue,
                                                    '&:hover': {
                                                        backgroundColor:
                                                        theme.colors.blue,
                                                        color: theme.colors
                                                            .white,
                                                        cursor: 'pointer',
                                                    },
                                                }}
                                                onClick={() => {
                                                    setNewStartModal(false)
                                                    window.location.href = '/login'
                                                }}
                                            >
                                                Schließen
                                            </Button>
                                        </Grid>
                                        <Grid textAlign={'right'} item xs={6}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    m: 2,
                                                    p: 1,
                                                    backgroundColor:
                                                    theme.colors.red,
                                                }}
                                                onClick={() => {
                                                    setNewStartModal(false)
                                                    $api.vif.getResultProtocol(uuid).then((resp) => {
                                                        const blobUrl = window.URL.createObjectURL(resp.data);
                                                        const link = document.createElement('a');
                                                        link.href = blobUrl;
                                                        link.download = 'Ergebnisprotokoll' + new Date().toISOString().slice(0, 10) + '.xlsx';
                                                        document.body.appendChild(link);
                                                        link.click();
                                                        document.body.removeChild(link);
                                                        window.location.href = '/login'
                                                    });


                                                }}
                                            >
                                                Protokoll herunterladen
                                                <ArrowRight
                                                    style={{
                                                        marginLeft: '0.5em',
                                                        width: '1em',
                                                        height: '1em',
                                                    }}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </>
            </Modal>
        </>
    )
}
